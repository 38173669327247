@mixin overflow($overflow: auto, $direction: default) {
  @if $direction == default {
    overflow: $overflow;
  } @else if $direction == "x" {
    @if $overflow == auto {
      overflow: hidden;
    }
    overflow-x: $overflow;
  } @else if $direction == "y" {
    @if $overflow == auto {
      overflow: hidden;
    }
    overflow-y: $overflow;
  }
  @if $overflow == auto {
    -webkit-overflow-scrolling: touch;
  }
}

.of-auto {
  @include overflow(auto);
}

.of-hidden {
  @include overflow(hidden);
}
