.image {
  max-width: 100%;
  @extend .image--md;
}

/* Image Positioning */

.image--center {
  margin: 0 auto;
  display: block;
}


/* Image Sizing */

.image--md {
  width: map-get($image, width);
}
.image--xl {
  width: map-get($image, width-xl);
}
.image--lg {
  width: map-get($image, width-lg);
}
.image--sm {
  width: map-get($image, width-sm);
}
.image--xs {
  width: map-get($image, width-xs);
}
