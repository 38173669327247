$highlight-background   : #f7f7f7;
$highlight-foreground   : #4d4d4c;
$highlight-comment      : #8e908c;
$highlight-red          : #c82829;
$highlight-orange       : #f5871f;
$highlight-yellow       : #eab700;
$highlight-green        : #718c00;
$highlight-aqua         : #3e999f;
$highlight-blue         : #4271ae;
$highlight-purple       : #8959a8;
