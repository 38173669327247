.article__content {
  code.success {
    color: $text-color-function;
    background-color: $green;
  }

  code.info {
    color: $text-color-function;
    background-color: $blue;
  }

  code.warning {
    color: $text-color-function;
    background-color: $yellow;
  }

  code.error {
    color: $text-color-function;
    background-color: $red;
  }
}
