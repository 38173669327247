@mixin split-line($direction: default, $width: default, $color: default) {
  @if $direction == default {
    $direction: top;
  }
  @if $color == default {
    $color: $border-color-l;
  }
  @if $width == default {
    $width: 1px;
  }
  border: 0 solid $color;
  @if $direction == top {
    border-top-width: $width;
  }
  @if $direction == right {
    border-right-width: $width;
  }
  @if $direction == bottom {
    border-bottom-width: $width;
  }
  @if $direction == left {
    border-left-width: $width;
  }
}
