@mixin modal($z-index: default, $color: default, $background-color: default) {
  @if $z-index == default {
    $z-index: map-get($z-indexes, modal);
  }
  @if $color == default {
    $color: $text-color-theme-dark;
  }
  @if $background-color == default {
    $background-color: $mask-color;
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index;
  width: 100%;
  height: 100%;
  color: $color;
  touch-action: none;
  background-color: $background-color;
  opacity: 0;
  @include transform(translate(100%, 0));
  @include transition(#{opacity map-get($animation, duration) map-get($animation, timing-function),
  transform 0s map-get($animation, duration) map-get($animation, timing-function)});
}
@mixin modal--show() {
  opacity: 1;
  @include transform(translate(0, 0));
  @include transition(#{opacity map-get($animation, duration) map-get($animation, timing-function)});
}

.modal {
  @include modal();
}
.modal--show {
  @include modal--show();
}
.modal--overflow {
  @include overflow(auto);
}
